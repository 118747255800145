<template>
  <div
    v-if="admin.roles.includes('super-admin') || auxPermission"
    class="card"
  >
    <div
      v-b-toggle="`collapseResearcher`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Researcher</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseResearcher`"
      visible
    >
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['search_researcher'] }}</label>
                <v-select
                  v-model="item.user"
                  label="name"
                  :options="users"
                  :get-option-key="option => option.id"
                  :placeholder="'Type 3 or more characters to search...'"
                  @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    vSelect,
    BCollapse,
  },
  props: {
    item: { type: Object, required: false, default: () => {} },
    onlyPromotionResearcher: { type: Number, required: false, default: null },
    auxPermission: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      admin: 'auth/admin',
    }),
  },
  watch: {
    'item.user': function () {
      if (this.item.user && !this.item.user.id) {
        this.item.user = null
      }
    },
  },
  async mounted() {
    if (this.onlyPromotionResearcher) {
      await this.$store.dispatch('users/fetchFakeUsers', {
        promotion: this.onlyPromotionResearcher,
      })
    }
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.search_researcher`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async onSearch(search) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', {
          name: search.name,
          promotion: this.onlyPromotionResearcher,
        })
      }
    },
  },
}
</script>
