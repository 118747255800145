<template>
  <div
    v-if="canSee"
    class="form-section-container"
  >
    <template v-if="block.type.toLowerCase() === 'title_section'">
      <h3 class="cart-title">
        {{ block.title }}
      </h3>
      <div class="mb-1">
        <p>{{ block.name }}</p>
        <hr>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'text_paragraph'">
      <p
        v-html="block.name"
      />
    </template>

    <template v-if="block.type.toLowerCase() === 'basic_input'">
      <label
        for=""
        class="form-label"
      >
        {{ block.title }}
        <span
          v-if="block.required"
          class="text-danger"
        >*</span>
      </label>
      <input
        v-model="block.answer"
        type="text"
        class="form-control"
      >
      <p><small>{{ block.help_text }}</small></p>
    </template>

    <template v-if="block.type.toLowerCase() === 'long_answer'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >
          {{ block.title }}
          <span
            v-if="block.required"
            class="text-danger"
          >*</span>
        </label>
        <textarea
          v-model="block.answer"
          type="text"
          class="form-control"
        />
        <p><small>{{ block.help_text }}</small></p>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'several_options'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >
          {{ block.title }}
          <span
            v-if="block.required"
            class="text-danger"
          >*</span>
        </label>
        <template v-if="block.design"><!-- Option in select -->
          <v-select
            v-model="block.answer"
            :multiple="block.answer_type"
            label="option"
            :options="block.options"
            :get-option-key="option => option.id"
          />
        </template>
        <template v-else><!-- Option in checkbox/radio -->
          <div
            v-for="option, index in block.options"
            :key="option.id"
            class="form-check mb-1"
          >
            <input
              :id="`selectOption-${index}-${block.id}`"
              v-model="block.answer"
              class="form-check-input"
              :true-value="[]"
              :type="block.answer_type ? 'checkbox' : 'radio'"
              :name="block.answer_type ? `checkbox-${index}` : 'radio'"
              :value="option.id"
            >
            <label
              class="form-check-label"
              :for="`selectOption-${index}-${block.id}`"
            >{{ option.option }}</label>
          </div>
        </template>
        <p><small>{{ block.help_text }}</small></p>
        <div v-if="(block.answer && Array.isArray(block.answer) && block.answer.findIndex(e => e.other) != -1) || (block.answer && typeof block.answer == 'object' && block.answer.other) || (block.options.find(e => e.id == block.answer) && block.options.find(e => e.id == block.answer).other)">
          <label
            for=""
            class="form-label"
          >
            {{ block.title }} - Other...
          </label>
          <input
            v-model="block.other_answer"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'date_hour'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >{{ block.title }} <span
          v-if="block.required"
          class="text-danger"
        >*</span></label>
        <div class="row">
          <div
            v-if="block.date"
            class="col-6"
          >
            <input
              id="fp-time"
              v-model="block.answer_date"
              type="date"
              class="form-control flatpickr-time text-start"
              placeholder="HH:MM"
            >
          </div>
          <div
            v-if="block.hour"
            class="col-6"
          >
            <input
              id="fp-time"
              v-model="block.answer_hour"
              type="time"
              class="form-control flatpickr-time text-start"
              placeholder="HH:MM"
            >
          </div>
        </div>
        <p><small>{{ block.help_text }}</small></p>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'download'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >{{ block.title }}</label>
        <p><small>{{ block.help_text }}</small></p>
        <a
          :href="block.file.url"
          target="_blank"
          class="btn btn-outline-primary"
        ><i data-feather="download-cloud" /> Download file</a>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'upload'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >{{ block.title }} <span
          v-if="block.required"
          class="text-danger"
        >*</span></label>
        <p><small>{{ block.help_text }}</small></p>
        <div
          class="input-group custom-file-button"
        >
          <label
            class="input-group-text"
            for="inputGroupFile"
          >Select file</label>
          <input
            id="inputGroupFile"
            :ref="`file-block-${block.id}`"
            class="form-control"
            type="file"
            @change="saveFile"
          >
        </div>
        <div
          v-if="block.answer && !block.answer.name"
          class="mt-50"
          v-html="block.answer"
        />
        <a
          v-if="block.answer && block.answer.name"
          class="mt-50"
          :href="block.answer.url"
          target="_blank"
        >{{ block.answer.name }}</a>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'linear_scale'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >{{ block.title }} <span
          v-if="block.required"
          class="text-danger"
        >*</span></label>
        <p><small>{{ block.help_text }}</small></p>
        <div class="row">
          <div class="col-auto">
            {{ block.initial_value }}
          </div>
          <div
            v-for="number in numbers"
            :key="number"
            class="col-auto"
          >
            <div class="form-check">
              <input
                :id="`AAselectOptionX-${number}`"
                v-model="block.answer"
                class="form-check-input"
                type="radio"
                name="AAselectOptionsRadio"
                :value="number"
              >
              <label
                class="form-check-label"
                :for="`AAselectOptionX-${number}`"
              >{{ number }}</label>
            </div>
          </div>
          <div class="col-auto">
            {{ block.final_value }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'multimedia'">
      <div class="mb-3">
        <label
          for=""
          class="form-label"
        >{{ block.title }}</label>
        <p><small>{{ block.help_text }}</small></p>
        <div class="row">
          <div
            v-if="block.file.extension === 'audio'"
            class="col-sm-6"
          >
            <!-- Audio player -->
            <audio
              controls
              :src="block.file.url"
            >
              Your browser does not support the audio element.
            </audio>
          </div>

          <div
            v-else-if="block.file.extension === 'video'"
            class="col-sm-6"
          >
            <!-- Video player -->
            <video
              style="width: 100%;"
              controls
              :src="block.file.url"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            v-else-if="block.file.extension === 'image'"
            class="col-sm-6"
          >
            <!-- Image display -->
            <img
              :src="block.file.url"
              alt="Image"
            >
          </div>
        </div>
      </div>
    </template>

    <template v-if="block.type.toLowerCase() === 'divider'">
      <hr class="hr__divider">
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    block: { type: Object, required: true },
  },
  data() {
    return {
      numbers: [],
    }
  },
  computed: {
    ...mapGetters({
      form: 'requestForms/item',
    }),
    canSee() {
      if (this.block.relation && this.block.relation.block.id && this.form.blocks) {
        return this.form.blocks.filter(e => {
          let matchAnswer = false
          const { answer } = e
          if (answer && Array.isArray(answer)) {
            matchAnswer = answer.findIndex(f => f.id === this.block.relation.option.id) != -1
          } else if (answer) {
            matchAnswer = answer.id === this.block.relation.option.id
          }

          return e.id === this.block.relation.block.id && matchAnswer
        }).length > 0
      }

      return true
    },
  },
  async mounted() {
    if (this.block.type.toLowerCase() === 'linear_scale') {
      for (let i = this.block.from; i <= this.block.to; i++) {
        this.numbers.push(i)
      }
    }

    if (this.block.type.toLowerCase() === 'several_options' && !this.block.design && this.block.answer_type && !Array.isArray(this.block.answer)) {
      this.block.answer = []
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFile() {
      const file = this.$refs[`file-block-${this.block.id}`].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: `file-block-${this.block.id}`,
      }

      this.block.answer = fileData
    },
  },
}
</script>
