<template>
  <div
    v-if="attached && attached.id"
    class="card card__request__form"
  >
    <div class="card-content">
      <div class="card-body">
        <FormSection
          v-for="block in attached.blocks"
          :key="block.id"
          :block="block"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormSection from './FormSection.vue'

export default {
  components: {
    FormSection,
  },
  props: {
    type: { type: String, required: true },
    modelId: { type: [String, Number], required: false, default: null },
  },
  computed: {
    ...mapGetters({
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('requestForms/fetchAttached', { type: this.type, model_id: this.modelId })
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {

  },
}
</script>
