<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ spinoff.id ? 'Edit' : 'New' }} spin off<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="spinoff.publish"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    >
                      <small class="ms-1">
                        {{ labels['active'] }}
                      </small>
                    </label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to professional background"
              @click="$router.push({name: 'researcher.spin-offs.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="user.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Spinoff')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <SearchResearcher :item="spinoff" />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.title'] }}</label>
                        <input
                          v-model="spinoff.name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.web'] }}</label>
                        <div class="input-group">
                          <input
                            v-model="spinoff.web"
                            type="text"
                            class="form-control"
                            placeholder="Ex.: https://www.icrea.cat"
                            aria-describedby="button-addon2"
                          >
                          <a
                            id="button-addon2"
                            :href="spinoff.web"
                            target="_blank"
                            class="btn btn-outline-primary"
                            type="button"
                          ><i data-feather="external-link" /></a>
                        </div>
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.description'] }}</label>
                        <quill-editor v-model="spinoff.description" />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h4>Dates</h4>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.born_in'] }}</label>
                        <date-picker
                          v-model="spinoff.born_in"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.closed_in'] }}</label>
                        <date-picker
                          v-model="spinoff.closed_in"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>

                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <label
                        for="formFile"
                        class="form-label"
                      >{{ labels['content.logo'] }}</label>
                      <div>
                        <span
                          v-if="icon_path"
                          v-html="icon_path"
                        />
                        <img
                          v-if="blob_url"
                          height="35px"
                          width="35px"
                          :src="blob_url"
                        >
                        <span
                          class="dis-ib float-end"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete social network"
                        ><a
                          href="#"
                          class="btn btn-icon rounded-circle btn-flat-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#modalDeleteWithoutInput"
                        ><i data-feather="trash-2" /></a>
                        </span></div>

                    </div>
                    <div class="col-sm-9">
                      <label
                        for="formFile"
                        class="form-label"
                      >{{ labels['content.upload_logo'] }}</label>
                      <input
                        id="formFile"
                        :ref="`file-icon`"
                        class="form-control"
                        type="file"
                        @change="loadIcon"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Entrepeneur
              :entrepeneurs="spinoff.entrepeneurs"
              :title="'Entrepeneurs'"
            />

            <AddFile
              :id="6"
              :title="'Files'"
              :type="3"
              :files="spinoff.files"
              @saveAnswer="saveFiles"
            />

            <AttachedForm
              :type="'Spinoff'"
              :model-id="spinoffId"
            />
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import 'vue2-datepicker/index.css'
import Entrepeneur from './components/Entrepeneur.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AddFile,
    DatePicker,
    Entrepeneur,
    SearchResearcher,
    AttachedForm,
  },
  data() {
    return {
      spinoffId: this.$route.params.id,
      loading: false,
      selectedInstitution: null,
      manualInstitution: false,
      icon_path: '',
      blob_url: '',
      file: null,
    }
  },
  computed: {
    ...mapGetters({
      spinoff: 'tecnologyTransfers/spinoff',
      users: 'users/fakeUsers',
      user: 'auth/admin', // Logged user
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'technology_transfer.spin_off_companies')
    if (this.spinoffId) {
      await this.$store.dispatch('tecnologyTransfers/fetchSpinoff', this.spinoffId)
    } else {
      this.$store.dispatch('tecnologyTransfers/clearSpinoff')
    }
  },
  methods: {
    async save() {
      this.loading = true
      this.spinoff.attached_form = this.attached

      if (this.spinoffId) {
        await this.$store.dispatch('tecnologyTransfers/updateSpinoff', { id: this.spinoffId, data: this.spinoff, icon: this.file })
      } else {
        await this.$store.dispatch('tecnologyTransfers/createSpinoff', { data: this.spinoff, icon: this.file })
      }
      this.loading = false
      Vue.swal('Spinoff updated successfully!', '', 'success').then(() => {
        this.$router.push({ name: 'researcher.spin-offs.index' })
      })
    },
    saveFiles(files) {
      this.spinoff.files_new = files.data
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    loadIcon() {
      this.file = this.$refs['file-icon'].files[0]
      this.icon_path = ''
      this.blob_url = URL.createObjectURL(this.file)
    },
  },
}
</script>
